import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'
const instance = createApp(App).use(store).use(router)
instance.use(vant)
instance.mount('#app')
