const data = {
  '440300': [{
    name: '瘦声Gym（深圳） - 招商中',
    address: '西湖区文三路99号',
    phone: '13330000719',
    abstract: `
    瘦声全系会员可享免费跑步机。
    瘦声 M 系列会员提供免费指导教学，免费享受所有器材，健身课程4折起。
    `,
    details: `
    瘦声同时也在招募优质的合作商，如果你的机构能够专业地解决用户的健康
    问题，欢迎致信marketing@slimhz.com。
    `,
    imgUrl: ['https://images.pexels.com/photos/6551098/pexels-photo-6551098.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'],
  },],
  '330100': [{
    name: '瘦声Gym（未营业）',
    address: '西湖区文三路99号',
    imgUrl: [''],
  }]
}
module.exports = data