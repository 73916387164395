<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      欢迎使用SlimHz App，此内容正在建设中，未来SlimHz功能会逐渐迁移。
    </p>
    <h3>可用功能</h3>
    <ul>
      <li><a href="#/nearby" target="_blank" rel="noopener">附近的健身房</a></li>
    </ul>
    <h3>访问其他内容</h3>
    <ul>
      <li><a href="https://www.slimhz.com/" target="_blank" rel="noopener">主页</a></li>
      <li><a href="https://www.slimhz.com/price.html" target="_blank" rel="noopener">购买</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SlimHz',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
