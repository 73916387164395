<template>
  <div>
    <van-row class="header">
      <van-col span="12" class="title">
        附近
      </van-col>
      <van-col span="12" class="area-button">
        <van-button 
          size="small"
          type="primary" 
          icon="location-o" 
          round 
          @click="showAreaSelector = true"
        >
          {{ selected.name }}
        </van-button>
      </van-col>
    </van-row>
    
    <van-list v-if="list.length">
      <gym-card
        v-for="item in list"
        :key="item.name"
        :config="item"
      />
    </van-list>
    <van-empty v-else description="您的所在地暂时没有服务点"></van-empty>
    <van-popup
      v-model:show="showAreaSelector"
      position="bottom"
    >
      <van-area 
        :value="selected.code"
        title="选择位置"
        :area-list="areaList"
        columns-num="2"
        @cancel="showAreaSelector = false" 
        @confirm="onSelected"   
      />
    </van-popup>

  </div>
</template>

<script>
import gymCard from './gymCard.vue' 
import { areaList } from '@vant/area-data';
import gymData from './gymData.js'
export default {
  components: {gymCard},
  data() {
    return {
      list : [],
      areaList,
      showAreaSelector: false,
      selected: {code: "440300", name: "深圳市"},
    }
  },
  methods:{
    onSelected(selected) {
      this.selected = selected.slice(-1)[0]
      this.showAreaSelector = false
      const { code } = this.selected
      if (code in gymData) 
        this.list = gymData[code] 
      else 
        this.list = []
      console.log(this.selected)
    }
  },
  mounted() {
    this.onSelected([this.selected])
  }
}
</script>

<style lang="less" scoped>
  .header {
    vertical-align: center;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .area-button {
    text-align: right;
  }
</style>