<template>
  <div ref="user">
  <div 
    ref="cardContainer"
    class="card-container"
    :style="containerStyle"
    :class="{
      'full-view-start': isExpand, 
      'full-view': expanded,
      'animation': animation,
    }" 
  >
  <div v-if="expanded" class="close" @click="unexpand">
    <van-icon name="close" color="white" size="36px"/>
  </div>

  <div 
    ref="card"
    class="card-view"
    :class="{'card-view-expanded': expanded}"
    :style="{'min-height': minHeight}"
    @click="expand"
  >
    <slot :expanded="expanded"></slot>
  </div>
  </div>
  <div v-if="isExpand" :style="{height: blankHeight}"></div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, nextTick } from 'vue'
export default {
  props: { config: {
    type: Object ,
    default: ()=>{},
  }},
  setup(){
    const isExpand = ref(false)
    const expanded = ref(false)
    const animation = ref(false)
    const user = ref(null)
    const cardContainer = ref(null)
    const card = ref(null)
    const body = window.document.documentElement
    const minHeight = ref(null)
    const containerStyle = reactive({left: null, top: null, width: null, height: null })
    onMounted(()=>{
      if (user.value.offsetHeight > card.value.offsetHeight) 
        minHeight.value = user.value.offsetHeight + 'px'
    })

    const noScroll = function (event) {
      event.preventDefault()
    }
    function disableScroll() {
      const addEvent = window.document.body.addEventListener
        addEvent("touchmove", noScroll, {passive: false})
        addEvent("wheel", noScroll, {passive: false})
    }
    function enableScroll() {
      const removeEvent = window.document.body.removeEventListener
      removeEvent("touchmove", noScroll, {passive: false})
      removeEvent("wheel", noScroll, {passive: false})
    }

    const blankHeight = ref(0)
    function expand() {
      if (isExpand.value) return
      isExpand.value = true
      const box = cardContainer.value
      containerStyle.top =box.offsetTop-body.scrollTop + 'px'
      containerStyle.left =box.offsetLeft-body.scrollLeft + 'px'
      containerStyle.width =box.clientWidth + 'px'
      containerStyle.height =box.clientHeight + 'px'
      blankHeight.value =box.clientHeight + 'px'
      console.log(blankHeight.value)
      disableScroll()
      setTimeout(()=>{
        animation.value = true
        expanded.value = true
      },100)
    }
    function unexpand() {
      expanded.value = false
      setTimeout(()=>{
        animation.value = false
        isExpand.value = false
        Object.assign(containerStyle,{top: null, left:null, width:null, height: null})
        blankHeight.value = 0
        enableScroll()
      },250)
    }

    return {
      user,
      isExpand,
      expanded,
      expand,
      unexpand,
      cardContainer,
      card,
      containerStyle,
      animation,
      blankHeight,
      minHeight,
    }
  },
}
</script>

<style lang="less" scoped>
  .animation {
    transition: all 0.25s;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }
  .card-container {
    position: relative;
    box-sizing: border-box;
  }
  .card-view {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.461);
    border-radius: 20px;
    overflow: hidden;
    background-color: white;
  }
  .full-view-start {
    position: fixed;
    z-index: 2;
    width: 100%;
    left: 0;
  }
  .full-view {
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0;
  }
  .card-view-expanded {
    border-radius: 0;
    transition: all 0.5s;
  }
</style>