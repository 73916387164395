<template>
  <card style="margin: 15px 0;">
    <template #default="{ expanded }">
      <div class="card">
        <div class="images">
          <div
            class="swipe"
            :style="{ backgroundImage: `url(${config.imgUrl[0]})` }"
          ></div>
        </div>
        <div class="descriptions">
          <header>{{ config.name }}</header>
          <article>
            <p>
              {{ config.abstract }}
            </p>
            <p v-if="expanded">
              {{ config.details }}
            </p>
            <span v-if="expanded && config.address">
              <van-icon name="location" /> {{ config.address }}
            </span>
          </article>

          <template v-if="expanded">
            <div class="notes">
              如果您遇到了任何问题，或需要举报商家，请点击这里，
              <a href="mailto:support@slimhz.com">联系我们</a>。
            </div>
            <div class="buttons">
              <van-button
                @click="goLocation"
                v-if="config.address"
                type="primary"
                icon="map-marked"
                round
              >
                前往
              </van-button>
              <van-button
                @click="callPhone"
                v-if="config.phone"
                type="primary"
                icon="phone"
                round
              >
                咨询
              </van-button>
            </div>
          </template>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { ref, onMounted, reactive, nextTick, toRefs } from "vue";
import card from "../../components/card.vue";
export default {
  components: { card },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { config } = props;
    function goLocation() {
      const { address } = config;
      window.open("https://uri.amap.com/search?view=map&keyword=" + address);
    }
    function callPhone() {
      const { phone } = config;
      window.location.href=("tel:" + phone);
    }
    return {
      goLocation,
      callPhone,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 100%;
  height: 100%;
}
.images {
  height: 280px;
}
.descriptions {
  box-sizing: border-box;
  padding-top: 280px;
  header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }
  article {
    font-size: 14px;
    line-height: 24px;
  }
  padding: 15px 20px;
}
.swipe {
  height: 280px;
  width: 100%;
  background-color: cornflowerblue;
  background-position: center;
}

.notes {
  margin-top: 15px;
  font-size: 10px;
  color: #999;
  a {
    color: #999;
    text-decoration: underline;
  }
}

.buttons {
  position: absolute;
  height: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  & > * {
    margin: 0 10px;
  }
}
</style>
